if (document.querySelector(".form-styling")) {
    const inputTimes = document.querySelectorAll("input[type='date']");

    inputTimes.forEach((item) => {
        let date = new Date().toISOString().split("T")[0];
        item.getAttribute("max");
        item.setAttribute("max", date);

        item.addEventListener("change", (e) => {
            let inputEl = item.closest(".input");
            if (inputEl.querySelector("div")) {
                inputEl.querySelector("div").remove();
            }
            let div = document.createElement("div");
            inputEl.appendChild(div);
            let tagDiv = inputEl.querySelector("div");
            tagDiv.innerHTML = `${new Date(e.target.value).toLocaleDateString()}`;
        });
    });
}
