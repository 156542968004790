//Cart Contact Form validation
const cartContactForm = document.querySelector("form.pricecheck-form");

if (cartContactForm) {
    cartContactForm.addEventListener("submit", (event) => {
        let isValid = true;

        for (let input of cartContactForm.elements) {
            if (input.required) {
                let error;

                if (input.type === "checkbox") {
                    const parentLabel = input.closest("label.pricecheck-form__checkbox");
                    error = parentLabel?.nextElementSibling;

                    if (!input.checked) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.style.display = "block";
                            error.style.marginTop = "5px";
                            error.textContent = window.TYPO3.lang.cartValidationCheckbox;
                            parentLabel.insertAdjacentElement("afterend", error);
                        }
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                    }
                } else if (input.type === "email") {
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    error = input.nextElementSibling;

                    if (!emailRegex.test(input.value.trim()) || !input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationEmail;
                            input.insertAdjacentElement("afterend", error);
                        }
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                    }
                } else if (input.type === "tel") {
                    const phoneRegex = /^[0-9+\-()\s]*$/;
                    error = input.nextElementSibling;

                    if (!phoneRegex.test(input.value.trim()) || !input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationPhone;
                            input.insertAdjacentElement("afterend", error);
                        }
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                    }
                } else {
                    error = input.nextElementSibling;

                    if (!input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationInputs;
                            input.insertAdjacentElement("afterend", error);
                        }
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                    }
                }
            }
        }

        if (!isValid) {
            const firstInvalidField = cartContactForm.querySelector(".validation-error");
            firstInvalidField?.previousElementSibling?.focus();
            event.preventDefault();
        }
    });

    cartContactForm.addEventListener("change", (event) => {
        const input = event.target;

        if (input.type === "checkbox" && input.required) {
            const parentLabel = input.closest("label.pricecheck-form__checkbox");
            const error = parentLabel?.nextElementSibling;

            if (input.checked && error && error.classList.contains("validation-error")) {
                error.remove();
            }
        } else if (input.type === "email" || input.type === "tel") {
            const error = input.nextElementSibling;

            if (error && error.classList.contains("validation-error")) {
                error.remove();
            }
        }
    });
}

//Contact & Warranty form validation
const forms = document.querySelectorAll("form.contactformular, form.Garantieregistrierungsformular");

function validateForm(form) {
    form.noValidate = true;

    // Select all file inputs within the form
    const fileInputs = form.querySelectorAll("input[type=\"file\"]");

    // Add event listener to each file input
    fileInputs.forEach(input => {
        input.addEventListener("change", (event) => {
            const fileInput = event.target;
            const files = fileInput.files;
            const parentGroup = fileInput.closest(".form-group");
            const validTypes = ["image/jpeg", "image/png", "application/pdf"];
            const maxSize = 4 * 1024 * 1024;
            let isValid = true;

            // Remove any existing error messages
            const existingError = fileInput.nextElementSibling;
            if (existingError && existingError.classList.contains("validation-error")) {
                existingError.remove();
                parentGroup && parentGroup.classList.remove("error-present");
            }

            // Validate each file
            for (const file of files) {
                // File type validation
                if (!validTypes.includes(file.type)) {
                    isValid = false;
                    const error = document.createElement("span");
                    error.className = "validation-error";
                    error.style.color = "#ac1e35";
                    error.style.fontWeight = "700";
                    error.textContent = window.TYPO3.lang.cartValidationUploadFile;
                    fileInput.insertAdjacentElement("afterend", error);
                    parentGroup && parentGroup.classList.add("error-present");
                }

                // File size validation
                if (file.size > maxSize) {
                    isValid = false;
                    const error = document.createElement("span");
                    error.className = "validation-error";
                    error.style.color = "#ac1e35";
                    error.style.fontWeight = "700";
                    error.textContent = window.TYPO3.lang.cartValidationFileSize;
                    fileInput.insertAdjacentElement("afterend", error);
                    parentGroup && parentGroup.classList.add("error-present");
                }
            }

            // Clear file input if invalid
            if (!isValid) {
                fileInput.value = "";
            }
        });
    });

    form.addEventListener("submit", (event) => {
        let isValid = true;

        for (let input of form.elements) {
            if (input.required) {
                let error;
                const parentGroup = input.closest(".form-group");

                if (input.type === "checkbox") {
                    const parentLabel = input.closest("label.form-check-label");
                    error = parentLabel?.nextElementSibling;

                    if (!input.checked) {
                        isValid = false;

                        
                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.style.display = "block";
                            error.style.marginTop = "5px";
                            error.textContent = window.TYPO3.lang.cartValidationCheckbox;
                            parentLabel.insertAdjacentElement("afterend", error);
                        }
                        parentGroup && parentGroup.classList.add("error-present");
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                        parentGroup && parentGroup.classList.remove("error-present");
                    }
                } else if (input.type === "email") {
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    error = input.nextElementSibling;

                    if (!emailRegex.test(input.value.trim()) || !input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationEmail;
                            input.insertAdjacentElement("afterend", error);
                        }
                        parentGroup && parentGroup.classList.add("error-present");
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                        parentGroup && parentGroup.classList.remove("error-present");
                    }
                } else {
                    error = input.nextElementSibling;

                    if (!input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationInputs;
                            input.insertAdjacentElement("afterend", error);
                        }
                        parentGroup && parentGroup.classList.add("error-present");
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                        parentGroup && parentGroup.classList.remove("error-present");
                    }
                }
            }
        }

        if (!isValid) {
            const firstInvalidField = form.querySelector(".validation-error");
            firstInvalidField?.previousElementSibling?.focus();
            event.preventDefault();
        }
    });

    form.addEventListener("input", (event) => {
        const input = event.target;
        if (input.required) {
            const error = input.nextElementSibling;
            if (error && error.classList.contains("validation-error")) {
                error.remove();
                const parentGroup = input.closest(".form-group");
                parentGroup && parentGroup.classList.remove("error-present");
            }
        }
    });

    form.addEventListener("change", (event) => {
        const input = event.target;

        if (input.type === "file") {
            const inputEl = input.closest(".input");

            // Remove existing file name display
            const existingSpan = inputEl.querySelector(".file-name-display");
            if (existingSpan) {
                existingSpan.remove();
            }

            if (input.files.length > 0) {
                const span = document.createElement("span");
                span.className = "file-name-display";
                inputEl.appendChild(span);
                span.innerHTML = `
                    ${input.files[0].name}
                    <button type="button">&#9587;</button>
                `;

                // Remove error message if present
                const error = input.nextElementSibling;
                if (error && error.classList.contains("validation-error")) {
                    error.remove();
                    const parentGroup = input.closest(".form-group");
                    parentGroup && parentGroup.classList.remove("error-present");
                }

                // Handle file removal
                const buttonClose = span.querySelector("button");
                if (buttonClose) {
                    buttonClose.addEventListener("click", () => {
                        input.value = null;
                        span.remove();

                        // Reapply validation logic if required and file is removed
                        if (input.required) {
                            const errorMessage = document.createElement("span");
                            errorMessage.className = "validation-error";
                            errorMessage.style.color = "#ac1e35";
                            errorMessage.style.fontWeight = "700";
                            errorMessage.textContent = window.TYPO3.lang.cartValidationInputs;
                            input.insertAdjacentElement("afterend", errorMessage);
                        }
                    });
                }
            }
        }
    });

    // Drag and Drop logic for files
    form.addEventListener("dragover", (event) => {
        event.preventDefault();
    });

    form.addEventListener("drop", (event) => {
        event.preventDefault();
        const input = event.target.closest(".input").querySelector("input[type=\"file\"]");
        if (input) {
            const files = event.dataTransfer.files;
            const validTypes = ["image/jpeg", "image/png", "application/pdf"];
            const maxSize = 4 * 1024 * 1024;
            let error = input.nextElementSibling;

            // Remove existing error messages for this specific input
            if (error && error.classList.contains("validation-error")) {
                error.remove();
            }

            for (const file of files) {
                if (!validTypes.includes(file.type)) {
                    error = document.createElement("span");
                    error.className = "validation-error";
                    error.style.color = "#ac1e35";
                    error.style.fontWeight = "700";
                    error.style.display = "block";
                    error.style.marginTop = "5px";
                    error.textContent = window.TYPO3.lang.cartValidationUploadFile;
                    input.insertAdjacentElement("afterend", error);
                    return;
                }
                if (file.size > maxSize) {
                    error = document.createElement("span");
                    error.className = "validation-error";
                    error.style.color = "#ac1e35";
                    error.style.fontWeight = "700";
                    error.style.display = "block";
                    error.style.marginTop = "5px";
                    error.textContent = window.TYPO3.lang.cartValidationFileSize;
                    input.insertAdjacentElement("afterend", error);
                    return;
                }
            }

            input.files = files;

            // Trigger change event to revalidate and update UI
            const changeEvent = new Event("change", { bubbles: true });
            input.dispatchEvent(changeEvent);
        }
    });
}

forms.forEach((form) => validateForm(form));
