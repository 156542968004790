window.scrollTo(0, 0);

let cards = document.querySelector(".header .cards");
let cardsicon = document.querySelector(".header .cards-icon");
let close = document.querySelector(".header .cards__close");

if (cards && cardsicon && close) {
    cards.style.opacity = "1";

    cardsicon.addEventListener("click", () => {
        cards.classList.toggle("cards--active");
    });

    close.addEventListener("click", () => {
        cards.classList.toggle("cards--active");
    });

}
