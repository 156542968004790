document.addEventListener("DOMContentLoaded", () => {
    const form = document.querySelector(".Garantieregistrierungsformular");
    if (form) {
        const serialNumberField = document.querySelector(".serialNumber");
        const warningMessage = document.createElement("span");
        warningMessage.style.color = "#ac1e35";
        warningMessage.style.fontWeight = "700";
        warningMessage.style.display = "none";
        warningMessage.textContent = window.TYPO3.lang.serialNumberValidationMessage;

        serialNumberField.parentNode.insertBefore(warningMessage, serialNumberField.nextSibling);

        const submitButton = form.querySelector('button[type="submit"]');
        const dataProtectionChecks = form.querySelectorAll('input[type="checkbox"]');
        const fileInputs = form.querySelectorAll('input[type="file"]');

        function validateSerialNumber() {
            const value = serialNumberField.value;
            const regex = /^(L[A-Z0-9]{2,4}|P[A-Z0-9]{2})-[A-Z0-9]+$/i;

            if (!regex.test(value)) {
                warningMessage.style.display = "block";
                return false;
            } else {
                warningMessage.style.display = "none";
                return true;
            }
        }

        function areAllCheckboxesChecked() {
            return Array.from(dataProtectionChecks).every((checkbox) => checkbox.checked);
        }

        function areAllRequiredFieldsFilled() {
            return Array.from(form.elements).every((input) => {
                if (input.required && input.type !== "file") {
                    return input.value.trim() !== "";
                }
                return true;
            });
        }

        function areRequiredFilesUploaded() {
            return Array.from(fileInputs).every((fileInput) => {
                return !fileInput.required || fileInput.files.length > 0;
            });
        }

        function validateForm() {
            return (
                validateSerialNumber() &&
                areAllCheckboxesChecked() &&
                areAllRequiredFieldsFilled() &&
                areRequiredFilesUploaded()
            );
        }

        serialNumberField.addEventListener("blur", validateSerialNumber);

        form.addEventListener("submit", (event) => {
            if (!validateForm()) {
                event.preventDefault();
            } else {
                setTimeout(() => {
                    submitButton.style.display = "none";
                }, 0);
            }
        });
    }
});
